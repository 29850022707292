// Generated by Framer (8184e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {cvHS7MaCj: {hover: true}};

const cycleOrder = ["cvHS7MaCj"];

const serializationHash = "framer-iMrf3"

const variantClassNames = {cvHS7MaCj: "framer-v-1hoithr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, imageThumbnail169, width, ...props}) => { return {...props, myDaJ8Dd2: imageThumbnail169 ?? props.myDaJ8Dd2} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;imageThumbnail169?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, myDaJ8Dd2, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cvHS7MaCj", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} as={"figure"} background={{alt: "", fit: "fill", sizes: "min(682px, 100vw)", ...toResponsiveImage(myDaJ8Dd2)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1hoithr", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"cvHS7MaCj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"cvHS7MaCj-hover": {"data-framer-name": undefined, background: {alt: "", fit: "fill", sizes: "min(582px, 100vw)", ...toResponsiveImage(myDaJ8Dd2)}}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-iMrf3[data-border=\"true\"]::after, .framer-iMrf3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iMrf3.framer-1jpxomz, .framer-iMrf3 .framer-1jpxomz { display: block; }", ".framer-iMrf3.framer-1hoithr { cursor: pointer; height: 388px; position: relative; width: 682px; }", ".framer-iMrf3.framer-v-1hoithr.hover.framer-1hoithr { aspect-ratio: 1.756838905775076 / 1; height: var(--framer-aspect-ratio-supported, 331px); width: 582px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 388
 * @framerIntrinsicWidth 682
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tNa8g7e8V":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"myDaJ8Dd2":"imageThumbnail169"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerWwqNVMVMJ: React.ComponentType<Props> = withCSS(Component, css, "framer-iMrf3") as typeof Component;
export default FramerWwqNVMVMJ;

FramerWwqNVMVMJ.displayName = "Project Thumbnail";

FramerWwqNVMVMJ.defaultProps = {height: 388, width: 682};

addPropertyControls(FramerWwqNVMVMJ, {myDaJ8Dd2: {title: "Image (thumbnail) 16:9", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerWwqNVMVMJ, [])